<template>
  <div style="height: 100%; width: 100%">
    <div class="banner-content">
      <div class="banner-text">
        <p class="p1">里格科技</p>
        <p class="p2">Rig Tech</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.banner-content {
  padding: 15%;
  background: url('../assets/top_banner_background_normal.png') no-repeat
    top/100%;
  color: #ffffff;
}
.banner-text {
  padding-top: 7%;
}
.p1 {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 700;
  font-size: 3.9vw;
  line-height: 5.6vw;
  text-align: center;
  letter-spacing: 0.25em;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.p2 {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 500;
  font-size: 2.23vw;
  line-height: 3.2vw;
  text-align: center;
  letter-spacing: 0.05em;

  color: #ffffff;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>
